<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-card
        class="mx-auto grid-item"
        tile
        :flat="!!item.image"
        v-bind="link"
      >
        <v-img
          v-if="item.image"
          itemprop="image"
          aspect-ratio="1.7"
          :src="item.image | resize('400x0')"
          :lazy-src="item.image | resize('100x0')"
          :srcset="srcset(item.image)"
          sizes="auto"
          :alt="item.title"
          class="align-end mb-2"
        >
          <template slot="placeholder">
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="#00A8E7"
              />
            </v-row>
          </template>
          <v-fade-transition>
            <v-overlay
              v-if="hover"
              absolute
              color="#60af40"
              @click.prevent="openPopup()"
            >
              <v-btn
                fab
                color="#005d77"
              >
                <v-icon>
                  mdi-magnify
                </v-icon>
              </v-btn>
            </v-overlay>
          </v-fade-transition>
        </v-img>
        <div class="content">
          <p
            v-if="item.subtitle"
            class="subtitle"
          >
            {{ item.subtitle }}
          </p>
          <h3 v-if="item.title">
            {{item.title}}
          </h3>
          <p
            v-if="item.text"
            class="text"
            v-text="item.text"
          />
          <p
            v-if="link"
            class="link"
          >
            Lees meer
          </p>
        </div>
      </v-card>
    </template>
  </v-hover>
</template>

<script>
  import PhotoSwipe from 'photoswipe/dist/photoswipe'
  import PhotoSwipeDefaultUI from 'photoswipe/dist/photoswipe-ui-default'

  export default {
    name: 'GridItem',
    props: {
      item: {
        type: Object,
        required: true
      }
    },
    computed: {
      link() {
        if (!this.$storyblok.isLink(this.item.link)) {
          return null
        }

        const link = this.$storyblok.url(this.item.link)

        return link.startsWith('/')
          ? {
            to: link,
            ...this.item.link.target ? {
              target: this.item.link.target
            } : {}
          }
          : {
            href: link,
            target: '_blank'
          }
      }
    },
    methods: {
      srcset(image, ratio) {
        const that = this;
        let src = '';

        [400, 600, 800, 1200, 1600].forEach((width) => {
          src += `${that.$options.filters.resize(image, `${width}x${ratio ? Math.round(width / ratio) : 0}`)} ${width}w, `;
        });

        src = src.slice(0, -2);

        return src;
      },
      openPopup() {
        if (!(this.item && this.item.image)) {
          return
        }

        const sizes = JSON.parse(this.$options.filters['data-size'](this.item.image));

        const pswp = new PhotoSwipe(
          document.querySelectorAll('.pswp')[0],
          PhotoSwipeDefaultUI,
          [{
            src: this.item.image,
            w: sizes.width,
            h: sizes.height,
            title: this.item.title
          }],
          {
            maxSpreadZoom: 1,
            index: 0,
            getDoubleTapZoom: function(isMouseClick, item) {
              return item.initialZoomLevel;
            }
          }
        );

        pswp.init()
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";

  .grid-item {
    .content {
      p {
        margin: 0;
      }

      .subtitle {
        color: map-get($brand-colors, 'green');
        font-size: .85rem;
        margin-top: $gutter-width;
      }

      h3 {
        color: map-get($brand-colors, 'blue');
        font-weight: $font-weight-bold;
      }

      .text {
        white-space: pre-line;
      }

      > a {
        text-decoration: none;
        color: map-get($brand-colors, 'orange');

        &:hover {
          color: darken(map-get($brand-colors, 'orange'), 10%);
        }
      }
    }

    .link {
      cursor: pointer;
      color: map-get($brand-colors, 'orange');
      transition: color .2s;

      &:hover {
        color: darken(map-get($brand-colors, 'orange'), 10%);
      }
    }
  }
</style>

