<template>
  <div v-if="initialized">
    <v-speed-dial
      v-model="opened"
      :direction="direction"
      id="share-favorites"
    >
      <template v-slot:activator>
        <v-btn
          v-model="opened"
          color="#005d77"
          dark
          fab
          small
        >
          <v-icon v-if="opened">mdi-close</v-icon>
          <v-icon v-else>mdi-share-variant</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        small
        color="#60af40"
        @click="copyToClipboard()"
      >
        <v-icon>mdi-link</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        color="#005d77"
        :href="`mailto:?subject=Mijn favorieten van Goede speelprojecten&body=Bekijk mijn favorieten middels de volgende link <a href='${shareLink}'>${shareLink}</a>`"
      >
        <v-icon>mdi-at</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        color="#128c7e"
        :href="`whatsapp://send?text=${shareLink}`"
      >
        <v-icon>mdi-whatsapp</v-icon>
      </v-btn>
    </v-speed-dial>
    <v-snackbar
      v-model="copied"
    >
      De link is gekopieerd naar het klembord
      <v-btn
        color="#f19727"
        text
        @click="copied = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-snackbar
      v-model="updatedFavorites"
    >
      Uw favorieten zijn geüpdatet
      <v-btn
        color="#f19727"
        text
        @click="updatedFavorites = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-dialog
      v-model="overwriteDialog"
      width="500"
    >
      <v-card>
        <v-card-title class="grey lighten-2">
          Favorieten
        </v-card-title>

        <v-card-text>
          <br>
          Wilt u uw huidige favorieten selectie overschrijven of de nieuwe producten eraan toevoegen?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="#005d77"
            text
            @click="closeOverwriteDialog(true)"
          >
            Overschrijven
          </v-btn>
          <v-btn
            color="#005d77"
            text
            @click="closeOverwriteDialog(false)"
          >
            Toevoegen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import _ from 'lodash'
  import setClipboard from '~/plugins/set-clipboard'

  export default {
  	name: 'ShareFavorites',
    data() {
  		return {
        opened: false,
			  mounted: false,
        copied: false,
        updatedFavorites: false,
			  overwriteDialog: false,
        initialized: false
      }
    },
    computed: {
      ids() {
        return this.$store.state.favorites
      },
  		direction() {
  			return this.$vuetify.breakpoint.smAndUp ? 'left' : 'bottom'
      },
      shareLink() {
	      const base = (process.env.BASE_URL || window.location.origin) + (
		      this.$storyblok.isLink(this.$store.state.settings.favoritesPage) ?
			      this.$storyblok.url(this.$store.state.settings.favoritesPage) : '/favorieten'
        )

        const ids = (this.ids).length ? JSON.parse(JSON.stringify(this.ids)).join(',') : this.$nuxt._route.query.favorites

	      return `${base}?favorites=${ids}`
      }
    },
	  watch: {
		  ids() {
			  if (!this.initialized) {
				  this.initialized = true
			  }
		  },
      initialized() {
	      if (this.$nuxt._route.query.favorites) {
	      	if ((this.ids).length) {
	      		this.overwriteDialog = true
	        } else {
	      		this.setFavorites()
	        }
	      }
      }
	  },
    methods: {
  		copyToClipboard() {
  			setClipboard(this.shareLink)
        this.copied = true;
      },
      closeOverwriteDialog(overwrite) {
        this.overwriteDialog = false
        this.setFavorites(overwrite)
      },
      setFavorites(overwrite = true) {
	      let favorites = (this.$nuxt._route.query.favorites).split(',')
	      favorites = overwrite ? favorites : [...JSON.parse(JSON.stringify(this.ids)), ...favorites]

	      this.$store.commit('setFavorites', _.uniq(favorites))
        this.updatedFavorites = true

	      this.$nuxt.$router.replace({path: this.$nuxt.$route.path})
      }
    },
    mounted() {
	    if (this.ids.length) {
	    	this.initialized = true
      }
    }
  }
</script>
