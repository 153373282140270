import { createHash } from 'crypto'

export const emptyImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII='

// https://medium.com/hceverything/applying-srcset-choosing-the-right-sizes-for-responsive-images-at-different-breakpoints-a0433450a4a3
export const defaultWidths = [640, 768, 1024, 1366, 1600, 1920]

/**
 * Resize
 *
 * @param {string} src
 * @param {string} option
 *
 * @returns {?string}
 */
export const resize = (src, option) => {
  if (typeof src !== 'string' || typeof src === 'undefined') {
    return null
  }

  if (/\.svg$/.test(src)) {
    return src
  }

  if (src.startsWith('http')) {
    src = src.replace(/https?:/, '')
  }

  return `//img2.storyblok.com/${option}${src.replace('//a.storyblok.com', '')}`
}

/**
 * Get height
 *
 * @param {number} imageWidth
 * @param {?string} [aspectRatio]
 *
 * @returns {number}
 */
const getHeight = (imageWidth, aspectRatio) => {
  if (!aspectRatio || !aspectRatio.includes(':')) {
    return 0
  }

  const [width, height] = aspectRatio.split(':').map(Number)

  return width && height ? Math.round((height / width) * imageWidth) : 0
}

/**
 * Get src
 *
 * @param {string} src
 * @param {number} width
 * @param {{}} [options = {}]
 * @param {?string} [options.aspectRatio]
 * @param {?string} [options.format]
 * @param {?string} [options.focus]
 * @param {?number} [options.quality = 90]
 * @param {?boolean} [options.smartCrop = true]
 * @param {?boolean} [options.contain = false]
 *
 * @returns {string}
 */
export const getSrc = (src, width, {
  aspectRatio,
  format,
  focus,
  quality = 90,
  smartCrop = true,
  contain = false
} = {}) => {
  const height = contain ? 0 : getHeight(width, aspectRatio)

  const filters = [
    ...format ? [`format(${format})`] : [],
    ...focus ? [`focal(${focus})`] : [],
    ...quality ? [`quality(${quality})`] : []
  ]

  const options = [
    `${width}x${height}`,
    smartCrop ? 'smart' : '',
    ...filters.length ? [
      `filters:${filters.join(':')}`
    ] : []
  ]

  return `${resize(src, options.join('/'))}`
}

/**
 * Get srcset
 *
 * @param {string} src
 * @param {number[]} widths
 * @param {{}} [options = {}]
 * @param {?string} [options.aspectRatio]
 * @param {?string} [options.format]
 * @param {?string} [options.focus]
 * @param {?number} [options.quality = 90]
 * @param {?boolean} [options.smartCrop = true]
 * @param {?boolean} [options.contain = false]
 *
 * @returns {string}
 */
export const getSrcset = (src, widths, {
  aspectRatio,
  format,
  focus,
  quality = 90,
  smartCrop = true,
  contain = false
} = {}) => widths.map(width => `${getSrc(src, width, {
  aspectRatio,
  format,
  focus,
  quality,
  smartCrop,
  contain
})} ${width}w`).join(',')

/**
 * Get resolution
 *
 * @param {string} src
 *
 * @returns {number[]}
 */
export const getResolution = src => {
  return ((src || '')
    .split('/')
    .find(value => /^\d+x[1-9](\d+)?$/.test(value)) || '')
    .split('x')
    .map(Number)
}

/**
 * Get padding top
 *
 * @param {string} src
 * @param {?string|?number} [defaultValue]
 *
 * @returns {?string}
 */
export const getPaddingTop = (src, defaultValue) => {
  const [width, height] = getResolution(src)

  return width && height ? `${(height / width) * 100}%` : defaultValue
}

const cache = new Map()

/**
 * Load image
 *
 * @param {string} src
 * @param {?string} [srcset]
 * @param {string} [sizes = 'auto']
 *
 * @returns {Promise<HTMLImageElement>}
 */
export const loadImage = (src, srcset, sizes = 'auto') => new Promise((resolve, reject) => {
  const cacheName = createHash('md5')
    .update(`${src}${srcset || ''}${sizes || ''}`)
    .digest('hex')

  if (cache.has(cacheName)) {
    return resolve(cache.get(cacheName))
  }

  const image = new Image()
  image.addEventListener('load', () => {
    cache.set(cacheName, image)

    resolve(image)
  })

  image.addEventListener('error', reject)

  if (srcset) {
    image.setAttribute('srcset', srcset)

    if (sizes) {
      image.setAttribute('sizes', sizes)
    }
  }

  image.setAttribute('src', src)
})
