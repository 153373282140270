<template>
  <v-card
    class="mx-auto product"
    flat
    nuxt
    :to="`/${item.slug}`"
    itemscope itemtype="http://schema.org/Product"
  >
    <meta itemprop="url" :content="`/${item.slug}`">
    <v-img
      v-if="item.image"
      itemprop="image"
      aspect-ratio=".9"
      :src="item.image | resize('300x0')"
      :lazy-src="item.image | resize('90x0')"
      :srcset="srcset(item.image, .9)"
      sizes="auto"
      :alt="item.title"
    >
      <template slot="placeholder">
        <v-row class="fill-height ma-0"
               align="center"
               justify="center"
        >
          <v-progress-circular indeterminate color="#00A8E7"></v-progress-circular>
        </v-row>
      </template>
      <div :class="{icon: true, favorite: isFavorite}" @click.prevent="toggleFavorite()">
        <v-icon v-if="isFavorite">icon-heart</v-icon>
        <v-icon v-else>icon-heart-outline</v-icon>
      </div>
    </v-img>
    <div class="text">
      <h3 itemprop="name">{{ item.title }}</h3>
      <p v-if="item.productLine" itemprop="category"><b>Productlijn:</b> {{item.productLine}}</p>
      <p v-if="item.articleNumber" itemprop="productID"><b>Artikelnummer:</b> {{item.articleNumber}}</p>
    </div>
  </v-card>
</template>

<script>
  export default {
  	name: 'ProductOverviewItem',
    props: ['item', 'uid'],
	  data() {
		  return {
			  isFavorite: this.item.favorite || false,
		  }
	  },
    computed: {
	    favorites() {
		    return this.$store.state.favorites
	    },
    },
  	methods: {
		  srcset(image, ratio) {
			  const that = this;
			  let src = '';

			  [300, 600, 900, 1600].forEach((width) => {
				  src += `${that.$options.filters.resize(image, `${width}x${ratio ? Math.round(width / ratio) : 0}`)} ${width}w, `;
			  });

			  src = src.slice(0, -2);

			  return src;
		  },
      toggleFavorite() {
	      let favorites = JSON.parse(JSON.stringify(this.favorites));

        if (favorites.includes(this.uid)) {
          favorites = favorites.filter(e => e !== this.uid)
          this.isFavorite = false;
	        this.$emit('unfavorite', this.uid);
        } else {
          favorites.push(this.uid)
          this.isFavorite = true;
        }

	      this.$store.commit('setFavorites', favorites);
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";

  .product {
    .v-image {
      border: 1px solid $border-color;
      border-radius: 0;
      position: relative;

      .icon {
        background: #fff;
        border-radius: 50%;
        height: 2rem;
        width: 2rem;

        z-index: 100;
        position: absolute;
        right: $gutter-width;
        top: $gutter-width;

        > .v-icon {
          position: absolute;
          color: grey;
          left: 0;
          top: 0;
          font-size: 1.5rem;

          &:before {
            position: relative;
            top: 1px;
          }
        }

        &.favorite, &:hover {
          > .v-icon {
            color: map-get($red, 'base');
          }
        }

        &.favorite:hover {
          > .v-icon {
            color: map-get($grey, 'base');
          }
        }
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;

        border: 2px solid map-get($brand-colors, 'blue');
        opacity: 0;
        transition: opacity .2s;
      }
    }

    .text {
      > h3 {
        color: map-get($brand-colors, 'blue');
        position: relative;
        display: inline-block;
        margin-top: 1rem;

        + p {
          margin-top: .4rem;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          height: 1px;
          width: 0;
          background: map-get($brand-colors, 'blue');
          transition: width .2s;
        }
      }

      > p {
        margin: 0;
        line-height: 1.5;
        font-size: .9rem;
      }
    }

    &:hover {
      .v-image {
        &:after {
          opacity: 1;
        }
      }

      .text {
        > h3 {
          &:after {
            width: 100%;
          }
        }
      }
    }
  }
</style>
