<template>
  <div v-editable="blok">
    <component :key="blok._uid" v-for="blok in blok.body" :blok="blok" :is="blok.component | dashify" />

    <section id="product-category-overview">
      <v-container>
        <v-row>
          <v-col cols="12" sm="5" md="4" lg="3">
            <Menu />
          </v-col>
          <v-col cols="12" sm="7" md="8" lg="9">
            <v-row dense>
              <v-col
                v-if="blok.title || blok.text"
                cols="12"
                class="mb-4"
              >
                <h1 v-if="blok.title">
                  {{ blok.title }}
                </h1>
                <div
                  v-if="blok.text"
                  v-html="$md.render(blok.text)"
                  :class="{'body': true, 'mt-4': blok.title}"
                />
                <p
                  v-if="(showProducts || categories.length) && (blok.titleUnderProducts || blok.textUnderProducts)"
                  class="read-more mt-3"
                  @click="scrollToExtraText"
                >
                  Lees meer <v-icon>mdi-chevron-down</v-icon>
                </p>
              </v-col>

              <v-col
                v-if="!blok.hideFilters"
                ref="filters"
                cols="12"
                class="filters"
              >
                <v-row>
                  <v-col
                    v-for="(filter, key) in filters"
                    :key="`filter-${key}`"
                  >
                    <v-select
                      clearable
                      hide-details
                      filled
                      v-model="selectedFilters[key]"
                      @input="changeFilters()"
                      no-data-text="Geen filteropties"
                      :multiple="filter.multiple"
                      :label="filter.label"
                      :items="filter.items"
                    />
                  </v-col>
                  <v-col>
                    <v-btn
                      block
                      depressed
                      class="reset-filters"
                      color="rgb(240, 240, 240)"
                      :disabled="!filterIsActive"
                      @click="resetFilters"
                    >
                      {{ $t('empty filters') }}
                      <span v-if="activeFilterCount > 0"> ({{ activeFilterCount }})</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                cols="12"
                :class="`mb-${filterIsActive ? '0' : '3'}`"
              >
                <v-divider />
              </v-col>

              <template v-if="showProducts &&  pages > 1">
                <v-divider />

                <v-pagination
                  v-model="page"
                  :length="pages"
                  :total-visible="10"
                  class="top-pagination"
                />
              </template>

              <template v-if="showProducts">
                <v-col
                  v-if="failedLoadingItems"
                  cols="12"
                >
                  <v-alert type="error">
                    Er is iets fout gegaan, de producten konden niet worden geladen. Probeer het a.u.b. later opnieuw.
                  </v-alert>
                </v-col>

                <v-col
                  v-else-if="!currentItems"
                  cols="12"
                >
                  <v-progress-linear
                    indeterminate
                    height="10"
                    color="#60af40"
                    class="my-8"
                  />
                </v-col>

                <template
                  v-else
                  v-for="item in currentItems"
                >
                  <v-col
                    v-if="item.active"
                    :key="item.uuid"
                    cols="12" sm="6" md="4"
                  >
                    <product-item :item="item" :uid="item.uuid"/>
                  </v-col>
                </template>
              </template>

              <v-col
                v-else
                v-for="(item, i) in categories" :key="i"
                cols="12" sm="6" md="4"
              >
                <v-card
                  class="mx-auto product-category-item"
                  nuxt
                  hover
                  tile
                  :flat="!!item.image"
                  :to="`/${item.slug}`"
                >
                  <v-img
                    v-if="item.image"
                    itemprop="image"
                    aspect-ratio="1.5"
                    :src="item.image | resize('400x0')"
                    :lazy-src="item.image | resize('100x0')"
                    :srcset="srcset(item.image)"
                    sizes="auto"
                    :alt="item.title"
                    gradient="rgba(0,93,119,0) 60%, rgba(0,93,119,.5)"
                    class="align-end"
                  >
                    <template slot="placeholder">
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular indeterminate color="#00A8E7" />
                      </v-row>
                    </template>
                    <v-card-title>
                      <span>{{ item.title }}</span>
                    </v-card-title>
                  </v-img>
                  <v-card-title
                    v-else
                    class="fill-height"
                  >
                    <span>{{ item.title }}</span>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>

            <template v-if="showProducts && pages > 1">
              <v-divider />

              <v-pagination
                v-model="page"
                :length="pages"
                :total-visible="10"
                v-on:input="scrollToTop"
              />
            </template>

            <v-col
              v-if="blok.titleUnderProducts || blok.textUnderProducts"
              cols="12"
              class="mt-2"
              ref="extra-text"
            >
              <h1 v-if="blok.titleUnderProducts">
                {{ blok.titleUnderProducts }}
              </h1>
              <div
                v-if="blok.textUnderProducts"
                v-html="$md.render(blok.textUnderProducts)"
                :class="{'body': true, 'mt-4': blok.titleUnderProducts}"
              />
            </v-col>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
  import VueScrollTo from 'vue-scrollto';

  import Menu from '~/components/pages/ProductCategoryOverview/Menu';
  import ProductItem from '~/components/pages/ProductOverview/Product';
  import { findIndex, orderBy } from 'lodash';

  import { productOverview } from '~/mixins/product-overview';

	export default {
		name: 'ProductCategoryOverviewPage',

    props: ['blok'],

    mixins: [ productOverview ],

    components: { Menu, ProductItem },

		data() {
			return {
				categories: []
			}
		},

    computed: {
		  showProducts() {
		    return !this.blok.hideFilters && this.filterIsActive;
      }
    },

    methods: {
			async loadCategories() {
				const storiesResult = await this.$storyapi.get('cdn/stories', {
					'filter_query[component][in]': 'page_product_overview,page_product_category_overview',
					'per_page': 100,
          'story_only': true,
          'is_published': true,
					'starts_with': this.$attrs.full_slug,
					'sort_by': 'position:DESC'
				});

				if (!storiesResult.data.stories) {
					return;
				}

				const productCategoryLinks = this.$store.state.productCategoryLinks;
				const depth = ((this.$attrs.full_slug).split('/')).length + 1;
        const productIndex = findIndex(productCategoryLinks, {'slug': this.$attrs.full_slug});

				let stories = {};
				storiesResult.data.stories.forEach((story) => {
          if (((story.full_slug).split('/')).length === depth) {
          	const index = productIndex >= 0 ?
              findIndex(productCategoryLinks[productIndex].children, {'uuid': story.uuid}) :
              findIndex(productCategoryLinks, {'uuid': story.uuid})
            stories[story.uuid] = {
              slug: story.full_slug,
              title: story.name,
              image: story.content.overviewImage || null,
              position: index >= 0 ? index : 999999
            };
          }
				});

				stories = orderBy(stories, ['position'], ['asc'])

				this.categories = stories;
			},

			srcset(image, ratio) {
				const that = this;
				let src = '';

				[400, 600, 900, 1600].forEach((width) => {
					src += `${that.$options.filters.resize(image, `${width}x${ratio ? Math.round(width / ratio) : 0}`)} ${width}w, `;
				});

				src = src.slice(0, -2);

				return src;
			},

      scrollToTop() {
        VueScrollTo.scrollTo(this.$refs.filters, { offset: -80 });
      },

      scrollToExtraText() {
        if (this.$refs['extra-text']) {
          VueScrollTo.scrollTo(this.$refs['extra-text'], { offset: -80 });
        }
      }
		},

		mounted() {
			this.loadCategories();
		}
	}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";

  .body ::v-deep {
    ul {
      margin-bottom: 1rem;
    }

    p + ul {
      margin-top: -1rem;
    }
  }

  #product-category-overview {
    > .container {
      max-width: $max-site-width;
    }

    ::v-deep .product {
      margin-top: $gutter-width;
    }
  }

  #app .v-pagination.top-pagination {
    margin-top: $gutter-width / 2;
    margin-bottom: -$gutter-width / 2;
  }

  .product-category-item {
    .v-card__title {
      font: {
        weight: $font-weight-bold;
        size: 1.25rem;
      }
    }

    .v-image {
      position: relative;

      .v-card__title {
        color: #fff;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
      }
    }
  }

  .v-btn.reset-filters {
    position: relative;
    height: 56px;

    text-transform: none;
    letter-spacing: normal;
    font-size: 1rem;

    color: rgba(0, 0, 0, .54);

    transition-property: box-shadow, transform, opacity, color;

    &:after {
      content: '';

      position: absolute;
      bottom: -1px;

      width: 100%;
      height: 1px;
      background: rgba(0, 0, 0, .42);
    }

    &:hover {
      color: rgba(0, 0, 0, .87);
    }
  }

  .filters {
    > .row > .col {
      min-width: 120px;
    }
  }

  .read-more {
    cursor: pointer;

    &, .v-icon {
      color: inherit;
      transition: color .2s;
    }

    &:hover {
      &, .v-icon {
        color: map-get($brand-colors, 'orange');
      }
    }
  }

  .top-pagination {
    width: 100%;
  }
</style>
