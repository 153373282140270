<template>
  <v-row class="product-gallery row mt-0">
    <v-col class="thumbnails shrink">
      <div
        ref="thumbnails"
        v-for="(item, i) in items"
        :key="i"
        :class="{'active': i === 0}"
        @click="goToSlide(i+1)"
      >
        <v-img
          v-if="item.image"
          itemprop="image"
          aspect-ratio="1.2"
          :src="item.image | resize('200x0')"
          :lazy-src="item.image | resize('10x0')"
          :srcset="srcsetThumb(item.image)"
          sizes="auto"
        >
          <template slot="placeholder">
            <v-row class="fill-height ma-0"
                   align="center"
                   justify="center"
            >
              <v-progress-circular indeterminate color="#00A8E7"></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </div>
    </v-col>
    <v-col class="carousel">
      <div ref="carousel" v-swiper:carouselSwiper="swiperOptions">
        <div class="swiper-wrapper">
          <div
            v-for="(slide, index) in items"
            :key="index"
            class="swiper-slide"
            itemscope itemtype="http://schema.org/ImageObject"
            itemprop="image"
            @click="openPopup(slide.image)"
          >
            <v-img
              v-if="slide.image"
              itemprop="contentUrl"
              :src="slide.image | resize('726x0')"
              :lazy-src="slide.image | resize('132x0')"
              :srcset="srcsetImage(slide.image)"
              aspect-ratio="1.2"
              sizes="auto"
            >
              <template slot="placeholder">
                <v-row class="fill-height ma-0"
                       align="center"
                       justify="center"
                >
                  <v-progress-circular indeterminate color="#00A8E7"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </div>
        </div>

        <div class="swiper-pagination" slot="pagination"></div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
	import PhotoSwipe from 'photoswipe/dist/photoswipe'
	import PhotoSwipeDefaultUI from 'photoswipe/dist/photoswipe-ui-default'
	import _ from 'lodash';

	export default {
		name: 'ProductGallery',
    props: {
	    items: {
		    type: Array,
		    default: () => []
	    },
    },
		data() {
			return {
				gallery: false,
				swiperOptions: {
					speed: 600,
					lazy: false,
					preloadImages: false,
					effect: 'fade',
					threshold: 5,
					parallax: true,
					loop: true,
					pagination: this.items.length > 1 ? {
						el: '.swiper-pagination',
						clickable: true
					} : false,
					autoplay: this.items.length > 1 ? {
						delay: 8000
					} : false,
					on: {
						slideChange: () => {
              if (this.carouselSwiper) {
                (this.$refs.thumbnails).find(function(thumb) {
                  return thumb.matches('.active')
                }).classList.remove('active');
                (this.$refs.thumbnails[this.carouselSwiper.realIndex]).classList.add('active')
              }
						}
					}
				}
			}
		},
		methods: {
			srcsetThumb(image, ratio) {
				const that = this;
				let src = '';

				[100, 200, 400].forEach((width) => {
					src += `${that.$options.filters.resize(image, `${width}x${ratio ? Math.round(width / ratio) : 0}`)} ${width}w, `;
				});

				src = src.slice(0, -2);

				return src;
			},
			srcsetImage(image, ratio) {
				const that = this;
				let src = '';

				[200, 400, 600, 900, 1200, 1400].forEach((width) => {
					src += `${that.$options.filters.resize(image, `${width}x${ratio ? Math.round(width / ratio) : 0}`)} ${width}w, `;
				});

				src = src.slice(0, -2);

				return src;
			},
      goToSlide(i) {
				if (this.carouselSwiper) {
          this.carouselSwiper.slideTo(i)
        }
      },
			initGallery() {
				if (this.items.length && !this.gallery) {
					const items = [];

					(this.items).forEach(item => {
						if (item.image && !item.video) {
							const sizes = JSON.parse(this.$options.filters['data-size'](item.image));
							items.push({
								src: item.image,
								w: sizes.width,
								h: sizes.height,
								title: item.title
							});
						}
					})

					this.gallery = {
						element: document.querySelectorAll('.pswp')[0],
						items: items,
						options: {
							maxSpreadZoom: 1,
							getDoubleTapZoom: function(isMouseClick, item) {
								return item.initialZoomLevel;
							}
						}
					};
				}
			},
			openPopup(source) {
				this.initGallery()

				const gallery = this.gallery;
				const pswp = new PhotoSwipe(
					gallery.element,
					PhotoSwipeDefaultUI,
					gallery.items,
					Object.assign(gallery.options, {index: _.findIndex(gallery.items, ['src', source])})
				);

				pswp.init()
			}
		},
	}
</script>

<style lang="scss" scoped>
  @import "./assets/scss/variables";
  $border-radius: 10px;
  $thumbnail-width: 100px + $grid-gutter;

  .product-gallery {
    position: relative;
    justify-content: flex-end;
  }

  .thumbnails {
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;

    // + $grid-gutter to ensure the scrollbar is hidden
    width: $thumbnail-width + $grid-gutter;
    overflow-y: auto;

    padding: {
      bottom: 0;
      top: 0;
    }

    @media (max-width: 500px) {
      display: none;
    }

    > div {
      width: 100px;
      position: relative;

      &:not(:last-child) {
        margin-bottom: .75rem;
      }

      &:after {
        content: '';

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        border-radius: $border-radius;
        border: 3px solid map-get($brand-colors, 'blue');
        opacity: 0;
        transition: opacity .2s;
      }

      &.active {
        &:after {
          opacity: 1;
        }
      }
    }

    .v-image {
      border-radius: $border-radius;
      border: 1px solid $border-color;
    }
  }

  ::v-deep .swiper-pagination-bullet {
    background: #fff;
    opacity: 1;

    &-active {
      background: map-get($brand-colors, 'blue');
    }
  }

  .carousel {
    min-width: 0;
    padding: {
      bottom: 0;
      top: 0;
    }

    @media (min-width: 501px) {
      max-width: calc(100% - #{$thumbnail-width});
      padding-left: 0;

      // Ofcourse this doesn't work in IE..
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        max-width: 100%;
        padding-left: $thumbnail-width;
      }
    }

    > div {
      border-radius: $border-radius;
      overflow: hidden;
      position: relative;
      width: 100%;
      height: 0;
      background: map-get($grey, 'lighten-3');

      padding-bottom: (1 / 1.2) * 100%;

      .swiper-wrapper {
        height: auto;
      }

      .swiper-slide {
        height: 100%;
        cursor: zoom-in;
      }
    }
  }
</style>
