<template>
	<section class="error-page">
		<v-container>
			<v-row justify="center">
				<v-col cols="12" md="10" lg="8" xl="6">
					<component :is="errorPage" :error="error" />
				</v-col>
			</v-row>
		</v-container>
	</section>
</template>

<script>
	import ErrorDefault from '~/components/error/Default';
	import Error404 from '~/components/error/404';

	export default {
		name: 'error',
		layout: 'empty',
		props: [
			'error'
		],
		components: {
			ErrorDefault: ErrorDefault,
			Error404: Error404
		},
		computed:
		{
			errorPage()
			{
				if (this.error.statusCode === 404) {
					return 'Error404';
				}

				return 'ErrorDefault';
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "@/assets/scss/_variables.scss";

	section
	{
		margin: $gutter-width * 3 auto;
	}
</style>