<template>
	<v-img
		v-if="blok.image"
		itemprop="image"
		aspect-ratio="1.75"
		:src="blok.image | resize('700x0')"
		:lazy-src="blok.image | resize('100x0')"
		:srcset="srcset(blok.image)"
		sizes="auto"
		:alt="blok.title"
	>
		<template slot="placeholder">
			<v-row class="fill-height ma-0"
								align="center"
				justify="center"
							>
				<v-progress-circular indeterminate color="#00A8E7"></v-progress-circular>
			</v-row>
		</template>
	</v-img>
</template>

<script>
	export default {
		props: ['blok'],
		methods: {
			srcset(image, ratio) {
				const that = this;
				let src = '';

				[300, 600, 900, 1600].forEach((width) => {
					src += `${that.$options.filters.resize(image, `${width}x${ratio ? Math.round(width / ratio) : 0}`)} ${width}w, `;
				});

				src = src.slice(0, -2);

				return src;
			}
		},
	}
</script>
