const redirects = {
  ...(require('../assets/redirects')),
  ...(require('../assets/product-redirects')),
  ...(require('../assets/product-category-redirects')),
  ...(require('../assets/product-climbcombinations-redirects'))
};

function redirect(res, url) {
  res.writeHead(301, { Location: url });
  res.end();
}

export default (req, res, next) => {
  let newUrl = false;
  if (/\/$/.exec(req.url)) {
    newUrl = req.url.replace(/\/$/, '');
  }

  if (req.url.startsWith('//')) {
    newUrl = req.url.replace(/^\/\//, '/');
  }

  if (/^\/sport-en-beweging\//.exec(req.url)) {
    newUrl = req.url.replace(/^\/sport-en-beweging\/(.*)/, `/sporttoestellen/$1`)
  }

  if (/^\/sporttoestellen\/beweegtuin\//.exec(req.url)) {
    newUrl = req.url.replace(/^\/sporttoestellen\/(.*)/, `/parkinrichting/$1`)
  }

  if (/^\/sporttoestellen\/interactief\//.exec(req.url)) {
    newUrl = req.url.replace(/^\/sporttoestellen\/(.*)/, `/speeltoestellen/$1`)
  }

  console.error([
    newUrl,
    req.url
  ]);

  if (redirects[newUrl || req.url]) {
    newUrl = redirects[newUrl || req.url];
  }

  const hostname = req.headers.host || '';
  if (hostname.includes('sportprojecten.com')) {
    newUrl = `${process.env.BASE_URL}/sporttoestellen`;
  }
  else if (
    hostname.includes('igym-outdoor.com') ||
    hostname.includes('igymoutdoor.com')
  ) {
    newUrl = `${process.env.BASE_URL}/sporttoestellen/igym-outdoor-fitness`;
  }

  if (hostname.startsWith('www.')) {
    newUrl = `${process.env.BASE_URL}${req.url}`;
  }

  if (newUrl) {
    redirect(res, newUrl);
  } else {
    next();
  }
}
