<template>
  <section
    v-editable="blok"
    ref="banner"
    :class="`banner${blok.color ? ` ${blok.color}` : ''}`"
    :style="paddingBottom"
    v-bind="attrs"
  >
    <div class="preloader" v-if="hasImage" />
    <banner-body :blok="blok" />
  </section>
</template>

<script>
	import _ from 'lodash';
  import { getSrc } from '~/assets/js/image';

	import BannerBody from '~/components/BannerBody';

	export default {
		name: 'Banner',

    props: ['blok'],

    components: { BannerBody },

    computed: {
      hasImage() {
        return this.blok.image || (this.blok.imageNew && this.blok.imageNew.filename);
      },

      isNewStyle() {
        return this.blok.imageNew && this.blok.imageNew.filename;
      },

      paddingBottom() {
        if (this.isNewStyle && this.blok.aspectRatio) {
          const [width, height] = this.blok.aspectRatio.split(':').map(Number)

          if (width && height) {
            return `padding-bottom: ${(height / width) * 100}%`
          }
        }

        return null
      },

      attrs() {
        if (this.isNewStyle) {
          return {
            'data-src': getSrc(
              this.blok.imageNew.filename,
              1920,
              {
                format: 'jpg',
                focus: this.blok.imageNew.focus,
                aspectRatio: this.blok.aspectRatio
              }
            ),
            'data-lowsrc': getSrc(
              this.blok.imageNew.filename,
              100,
              {
                format: 'jpg',
                focus: this.blok.imageNew.focus,
                aspectRatio: this.blok.aspectRatio
              }
            )
          }
        } else if (this.blok.image) {
          return {
            'data-src': this.blok.image,
            'data-lowsrc': this.$options.filters.resize(this.blok.image, '100x0')
          }
        }

        return {}
      }
    },

		mounted() {
			const $banner = this.$refs.banner;

			if (this.hasImage && $banner !== undefined) {
				if ($banner.dataset.src) {
					const img = new Image();

					const $preloader = $banner.querySelector('.preloader');
					$preloader.style.backgroundImage = `url('${$banner.dataset.lowsrc}')`;

					const sizes = [720, 1280, 1600, 2000];
					const width = _.find(sizes, function (x) { return (window.innerWidth * window.devicePixelRatio) <= x });

          if (this.isNewStyle) {
            img.src = width ? getSrc(
              this.blok.imageNew.filename,
              width,
              {
                format: 'jpg',
                focus: this.blok.imageNew.focus,
                aspectRatio: this.blok.aspectRatio
              }
            ) : $banner.dataset.src;
          } else {
            img.src = width ? this.$options.filters.resize($banner.dataset.src, `${width}x0`) : $banner.dataset.src;
          }

					img.onload = function() {
						$banner.style.backgroundImage = `url('${img.src}')`;
						$preloader.classList.add('img-loaded');
					};
				}
				else {
					$banner.remove();
				}
			}
		}
	}
</script>
