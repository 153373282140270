export default ({app}) => {
  const clean = string => string.replace(/(email|hash|token)=[^&]+&?/g, '');

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    originalLocation: document.location.protocol + '//' +
      document.location.hostname +
      document.location.pathname +
      clean(document.location.search)
  });

  app.router.afterEach((to) => {
    setTimeout(() => {
      window.dataLayer.push(to.gtm || {
        event: 'nuxtRoute',
        pageType: 'PageView',
        pageUrl: clean(to.fullPath),
        routeName: to.name
      })
    }, 0)
  })

}
