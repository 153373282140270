<template>
  <div
    v-resize="resize"
    class="off-canvas-menu"
  >
    <v-slide-y-transition>
      <div
        v-show="isOpen"
        class="list"
      >
        <nav
          class="overflow"
          role="navigation"
        >
          <off-canvas-menu-item
            v-for="item in items"
            :key="item._uid"
            :blok="item"
          />
        </nav>
      </div>
    </v-slide-y-transition>
  </div>
</template>

<script>
	import OffCanvasMenuItem from '@/components/Menu/OffCanvas/Item';

	export default {
		name: 'OffCanvasMenu',
		components: { OffCanvasMenuItem },
		data: () => ({
			isOpen: false
		}),
		computed: {
			items() {
				return this.$store.state.settings.mainNav || [];
			}
		},
		watch: {
			$route() {
				this.close();
			}
		},
		methods: {
			open() {
				if (this.isOpen) {return;}

				this.isOpen = true;
				this.$emit('open');
			},

			close() {
				if (!this.isOpen) {return;}

				this.isOpen = false;
				this.$emit('close');
			},

			resize() {
				if (!this.$vuetify.breakpoint.xsOnly) {
					this.close();
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
  @import '~assets/scss/variables.scss';

  $z-index: 100;

  .off-canvas-menu {
    position: relative;
    z-index: $z-index;

    .list {
      display: flex;
      flex-direction: column;
      padding: 0;

      position: fixed;
      top: $header-height-small;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: $z-index + 1;

      background-color: $offcanvas-menu-background;
    }

    .overflow {
      flex-grow: 1;
      max-height: 100%;
      overflow: {
        x: hidden;
        y: auto;
      }
    }
  }
</style>
