<template>
	<header class="at-top" ref="header">
		<v-container fluid class="fill-height py-0">
			<v-row align="center" class="fill-height">
				<v-col class="logo">
					<nuxt-link to="/">
						<img
              src="/logo.svg"
              alt="Goede speelprojecten"
            >
					</nuxt-link>
				</v-col>
				<v-col class="fill-height py-0">
          <client-only>
            <on-canvas-menu v-if="menuBreakpoint"/>
            <off-canvas-menu
              @open="toggleNoScroll(true)"
              @close="toggleNoScroll(false)"
              ref="off-canvas-menu"
              v-else
            />
          </client-only>
        </v-col>
        <v-col class="shrink search">
          <v-slide-x-reverse-transition v-if="searchBar">
            <v-text-field
              ref="search-bar"
              v-model="searchValue"
              filled rounded
              hide-details
              spellcheck="false"
              label="Zoek op bv. Productnaam, productnummer, ..."
              @keyup.enter="submitSearch"
            />
          </v-slide-x-reverse-transition>
          <v-icon color="#00A8E7" large @click="toggleSearchBar">
             {{searchBar ? 'mdi-close' : 'mdi-magnify'}}
          </v-icon>
        </v-col>
        <v-col class="shrink px-0 favorites">
          <v-badge
            overlap
            color="red"
            v-model="showFavoriteBadge"
          >
            <template v-slot:badge>
              {{ favoriteCount }}
            </template>
            <v-icon
              large
              color="#f19727"
              @click="goToFavorites"
            >
              {{ favoriteCount ? 'icon-heart' : 'icon-heart-outline'}}
            </v-icon>
          </v-badge>
        </v-col>
        <v-col class="shrink contact">
          <v-menu
            open-on-hover
            bottom
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                large
                color="#60af40"
                outlined
                rounded
                @click="goToContactPage"
                v-bind="attrs"
                v-on="on"
              >
                Over ons
              </v-btn>
            </template>
            <v-list>
              <template v-for="(item, i) in $store.state.settings.footerMenu1">
                <v-list-item
                  v-if="$storyblok.isLink(item.to)"
                  :key="i"
                  :to="$storyblok.url(item.to)"
                >
                  {{ item.text }}
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
        </v-col>
				<v-col class="shrink hidden-md-and-up">
					<Hamburger/>
				</v-col>
			</v-row>
		</v-container>
	</header>
</template>

<script>
	import EventBus from '~/plugins/event-bus';
	import Hamburger from '@/components/ui/Hamburger';
	import OffCanvasMenu from '@/components/Menu/OffCanvas/Main';
	import OnCanvasMenu from '@/components/Menu/OnCanvas/Main';

	export default {
		components: { Hamburger, OnCanvasMenu, OffCanvasMenu },
		created () {
			EventBus.$on('toggleMenu', (bool) => {
				if (this.$refs['off-canvas-menu']) {
          if (bool) {
            this.$refs['off-canvas-menu'].open()
          } else {
            this.$refs['off-canvas-menu'].close()
          }
        }
			})
		},
		data() {
			return {
				showFavoriteBadge: this.$store.getters.favoriteCount > 0,
        searchBar: false,
        searchValue: ''
			}
		},
    watch: {
      favoriteCount(count) {
        this.showFavoriteBadge = count > 0
      }
    },
    methods: {
	    toggleNoScroll(value) {
		    this.$store.commit('page/setNoScroll', value);
	    },
      goToFavorites() {
        if (this.$storyblok.isLink(this.$store.state.settings.favoritesPage)) {
          this.$router.push(this.$storyblok.url(this.$store.state.settings.favoritesPage))
        }
      },
      toggleSearchBar() {
	      this.searchBar = !this.searchBar

        if (this.searchBar) {
          this.$nextTick(() => {
            (this.$refs['search-bar']).focus()
          })
        }
      },
      submitSearch() {
	    	if (this.searchValue) {
			    this.$router.push({
            path: '/zoeken',
            query: {
              search: this.searchValue
			      }
			    });

          this.searchBar = false;
			    this.searchValue = '';
        }
      },
      goToContactPage() {
        if (this.$storyblok.isLink(this.$store.state.settings.contactPage)) {
          this.$router.push(this.$storyblok.url(this.$store.state.settings.contactPage))
        }
      }
    },
    computed: {
	    favoriteCount() {
		    return this.$store.getters.favoriteCount
	    },
	    menuBreakpoint () {
		    return this.$vuetify.breakpoint.mdAndUp
	    }
    },
		mounted () {
			const $body = window.document.body;
			const $header = this.$refs.header;
			let offset = (window.innerHeight / (window.innerHeight > window.innerWidth ? 4 : 2)) - $header.clientHeight;

			$body.onscroll = function() {
				$header.classList[window.scrollY > offset ? 'remove' : 'add']('at-top');
			};

			$body.onresize = function() {
				offset = (window.innerHeight / 2) - $header.clientHeight;
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "@/assets/scss/_variables.scss";

	header
	{
		z-index: 100;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;

		height: $header-height-small;

		display: flex;
		flex-wrap: wrap;

		background: #fff;
		transition: all .2s;

    .contact .v-btn {
      border-width: 3px;
      border-color: map-get($brand-colors, 'blue-grey');
      text-transform: none;
      letter-spacing: normal;
      font: {
        weight: 700;
        size: 1rem;
      }
    }

    @media (min-width: map-get($grid-breakpoints, 'md')) {
			&.at-top {
				height: $header-height;

        ::v-deep .sub-menu {
          top: $header-height;
        }
			}

			align-items: center;

      > .container {
        max-width: $max-site-width;
      }
		}

    @media #{map-get($display-breakpoints, 'xs-only')} {
      .contact {
        display: none;
      }

      .favorites {
        margin-right: $gutter-width;
      }
    }

		.logo
		{
			height: 100%;
			display: flex;
			align-items: center;
      max-width: 200px;
      min-width: 120px;

      + div {
        @media (max-width: 340px) {
          padding: 0;
        }
      }

			> a
			{
				height: 75%;
				width: 100%;

        @media (min-width: map-get($grid-breakpoints, 'md')) {
					height: 100%;
				}
			}

			svg, img
			{
				display: block;
				height: 100%;
				width: auto;
				max-width: 100%;
			}
		}

    .search {
      position: relative;
      z-index: 10;

      .v-input {
        position: absolute;
        right: 0;
        top: 0;
        width: 55vw;
        min-width: 200px;
        max-width: 800px;

        &.v-text-field--filled {
          &, &:hover {
            ::v-deep .v-input__slot {
              background: map-get($grey, 'lighten-3');
            }
          }
        }
      }

      .v-icon {
        position: relative;
        z-index: 10;
      }
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .shrink {
        min-width: 50px;

        &.contact {
          min-width: 150px;
        }
      }
    }

    .on-canvas {
      height: 100%;
    }
	}
</style>
