<template>
	<section v-editable="blok">
		<v-container>
			<v-row>
        <v-col cols="12" sm="6" md="3">
          <ul>
            <li>
              <b>Nederland</b>
            </li>
            <li>
              <b><span>Goede Speelprojecten B.V.</span></b>
            </li>
            <li></li>
            <li>
              Kraaivenstraat 23-12
            </li>
            <li>
              5048 AB Tilburg
            </li>
            <li></li>
            <li>
              <b><span>T</span> <a href="tel:+314551605">(013) 455 16 05</a></b>
            </li>
            <li>
              <b><span>E</span> <a href="mailto:goede@speelprojecten.nl">goede@speelprojecten.nl</a></b>
            </li>
            <li></li>
            <li>
              KvK nr. 350 30 213
            </li>
            <li>
              BTW nr. NL 8179.54.284.B.01
            </li>
            <li>
              IBAN NL70ABNA0532180585
            </li>
          </ul>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <ul>
            <li>
              <b>België</b>
            </li>
            <li>
              <span><b>Goede Speelprojecten bv</b></span>
            </li>
            <li></li>
            <li>
              Brusselstraat 51
            </li>
            <li>
              2018 Antwerpen
            </li>
            <li></li>
            <li>
              <b><span>T</span> <a href="tel:+324824067">(03) 482 4067</a></b>
            </li>
            <li>
              <b><span>E</span> <a href="mailto:goede@speelprojecten.be">goede@speelprojecten.be</a></b>
            </li>
            <li></li>
            <li>
              RSZ nr. 1928126-22
            </li>
            <li>
              Fiscaal nr. BE 0897.255.542
            </li>
            <li>
              IBAN BE65733048855896
            </li>
          </ul>
        </v-col>
				<v-col cols="12" sm="8" offset-sm="2" md="6" offset-md="0">
					<v-alert
						:value="formSent"
						type="info"
						color="#005d77"
					>
						Bedankt voor je bericht! Wij nemen z.s.m. contact met je op.
					</v-alert>
					<v-form
						ref="form"
						v-model="valid"
						lazy-validation
            @submit.prevent="submit"
						v-if="!formSent"
					>
            <p>Naam:</p>
						<v-text-field
              outlined
              solo flat
							v-model="values.name"
							label="Uw naam"
						></v-text-field>

            <p>Organisatie:</p>
            <v-text-field
              v-model="values.organization"
              :rules="organizationRules"
              outlined
              solo flat
              label="Naam van uw organisatie"
              required
            ></v-text-field>

            <p>E-mail:</p>
						<v-text-field
              outlined
              solo flat
							v-model="values.email"
							:rules="emailRules"
							label="Uw e-mailadres"
							required
							append-icon="mdi-at"
						></v-text-field>

            <p>Telefoon:</p>
            <v-text-field
              v-model="values.telephone"
              :rules="telephoneRules"
              outlined
              solo flat
              label="0612345678"
              required
            ></v-text-field>

            <p>Vraag / opmerking:</p>
						<v-textarea
              outlined
              solo flat
							label="Vul hier uw vraag en/of opmerking in"
							v-model="values.remark"
							:rules="remarkRules"
							required
						></v-textarea>

						<p class="recaptcha">Deze site wordt beschermd door reCAPTCHA en de <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> van Google en de <a href="https://policies.google.com/terms" target="_blank">Algemene voorwaarden</a> zijn van toepassing.</p>

            <v-checkbox
              v-model="values.agreed"
              :input-value="true"
              :rules="[v => !!v || 'U dient akkoord te gaan met de voorwaarden']"
            >
              <div slot="label">
                Hierbij verleen ik aan Goede Speelprojecten toestemming tot verwerking van mijn persoonsgegevens.
                Voor verdere informatie hieromtrent zie <span @click.capture.stop.prevent="goToPrivacy" id="go-to-privacy">Privacy Verklaring</span>.
              </div>
            </v-checkbox>

            <p>&nbsp;</p>

            <v-row justify="center">
              <v-col cols="8" sm="6" md="5" lg="4">
                <v-btn
                  type="submit"
                  block
                  rounded
                  x-large
                  outlined
                  color="#f19727"
                  class="submit"
                  :disabled="!valid"
                  :loading="mailing"
                >
                  Versturen
                  <v-icon right>icon-paper-plane</v-icon>
                </v-btn>
              </v-col>
            </v-row>
					</v-form>
				</v-col>
			</v-row>
		</v-container>
	</section>
</template>

<script>
	export default {
		name: 'ContactForm',
		props: ['blok'],
		data() {
			return {
				mailing: false,
				formSent: false,
				loading: false,
				recaptcha: null,
				valid: false,
				emailRules: [
					v => !!v || 'E-mailadres is verplicht',
					v => /@/.test(v) || 'E-mailadres is niet geldig'
				],
				remarkRules: [
					v => !!v || 'Dit veld is verplicht',
				],
        organizationRules: [
            v => !!v || 'Dit veld is verplicht',
        ],
        telephoneRules: [
            v => !!v || 'Dit veld is verplicht',
        ],
				values: {
					name: '',
					email: '',
					organization: '',
          telephone: '',
					remark: '',
          agreed: false
				},
			}
		},
		async mounted()
		{
			this.recaptcha = await this.$recaptcha();
		},
		methods: {
			async submit()
			{
				this.valid = this.$refs.form.validate();

				if (!this.valid) return;

				this.mailing = true;

				try
				{
					this.loading = true;

					const token = await this.recaptcha.execute('contact');

					await this.$axios.$post('/api/contact', {
						data: {
							'Organisatie': this.values.organization,
							'Naam': this.values.name,
							'E-mailadres': this.values.email,
							'Telefoon': this.values.telephone,
							'Toelichting': this.values.remark
            }, subject: this.blok.subject, mail_to: this.values.email, token});

					if (this.$storyblok.isLink(this.blok.page)) {
						await this.$nuxt.$router.push({path: this.$storyblok.url(this.blok.page)})
					} else {
						this.formSent = true;
					}

					this.loading = false;
					this.mailing = false;
				}
				catch (e)
				{
					console.error(e.response && e.response.data || e);

					this.loading = false;
					this.mailing = false;
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "@/assets/scss/_variables.scss";

	section {
		> .container {
      max-width: $max-site-width;

      p {
        margin: 0 0 $gutter-width / 2 0;
        font-size: 110%;
      }

      ul {
        list-style: none;

        > li {
          min-height: .75rem;

          span {
            color: map-get($brand-colors, 'green')
          }

          a {
            color: $text-color;
            text-decoration: none;
            display: inline-block;
            position: relative;

            &:before {
              content: '';
              position: absolute;
              bottom: .1rem;
              height: 1px;
              background: map-get($brand-colors, 'blue-grey');

              left: 50%;
              width: 0;
              opacity: 0;

              transition: left .2s, width .2s, opacity .2s;
            }

            &:hover {
              color: map-get($brand-colors, 'green');

              &:before {
                left: 0;
                opacity: 1;
                width: 100%;
              }
            }
          }
        }
      }

      ::v-deep .v-label {
        font-style: italic;
        width: 100%;
        color: map-get($grey, 'lighten-1');
      }

      .v-btn.submit {
        background: #fff;
        font-weight: $font-weight-bold;
        text-transform: none;
        letter-spacing: 0;

        .v-icon {
          font-size: 1.5rem;
        }
      }

      .v-input--checkbox {
        ::v-deep .v-icon {
          pointer-events: none;
          position: relative;

          &:before {
            z-index: 10;
          }

          &:after {
            content: '';
            position: absolute;
            top: 4px;
            left: 4px;
            right: 4px;
            bottom: 4px;
            background: #fff;
            z-index: 1;
          }
        }

        ::v-deep label {
          color: $text-color;
          font-style: normal;
        }

        &.v-input--is-label-active {
          ::v-deep .v-icon {
            color: map-get($brand-colors, 'blue');
          }
        }
      }

      #go-to-privacy {
        color: $link-color;
        text-decoration: underline;
        transition: color .2s;

        &:hover {
          color: $link-hover-color;
        }
      }

      .recaptcha {
        font-size: 100%;
      }
		}
	}
</style>
