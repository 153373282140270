<template>
	<section class="text" v-editable="blok">
		<v-container>
			<v-row>
				<v-col
          class="text-sm-right" cols="12" sm="6"
          :class="blok.imagePosition === 'left' && blok.imageAlignment ? `align-self-${blok.imageAlignment}` : false"
				>
					<ImageBlock
						:blok="blok"
						v-if="blok.imagePosition === 'left'"
					/>
					<TextBlock
						:blok="blok"
						v-else
					/>
				</v-col>
				<v-col
          cols="12"
          sm="6"
          :class="blok.imagePosition !== 'left' && blok.imageAlignment ? `align-self-${blok.imageAlignment}` : false"
				>
					<TextBlock
						:blok="blok"
						v-if="blok.imagePosition === 'left'"
					/>
					<ImageBlock
						:blok="blok"
						v-else
					/>
				</v-col>
			</v-row>
		</v-container>
	</section>
</template>

<script>
	import TextBlock from '~/components/TextImage/Text';
	import ImageBlock from '~/components/TextImage/Image';

	export default {
		name: 'TextImage',
		props: ['blok'],
		components: { TextBlock, ImageBlock },
	}
</script>
