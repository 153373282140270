import VuexPersistence from 'vuex-persist'

export default ({ store }) => {
  window.onNuxtReady(() => {
    new VuexPersistence({
      key: 'goede-speelprojecten',
      storage: window.localStorage,
      reducer: (state) => ({ favorites: state.favorites }),
    }).plugin(store);

    new VuexPersistence({
      key: 'goede-speelprojecten',
      storage: window.sessionStorage,
      reducer: (state) => ({ filters: state.filters }),
    }).plugin(store);
  });
}
