module.exports = {
  productLine: {
    label: 'Productlijn',
    items: [
      { text: 'Robuust robinia', value: 'robuust robinia' },
      { text: 'Robuust staal', value: 'robuust staal' },
      { text: 'Varioset mini', value: 'varioset mini' },
      { text: 'Kidstramps', value: 'kidstramps' },
      { text: 'Speelkunst', value: 'speelkunst' },
      { text: 'Hightech adrenaline', value: 'hightech adrenaline' },
      { text: 'Hightech fun4all', value: 'hightech fun4all' },
      { text: 'Hightech klimtollen', value: 'hightech klimtollen' },
      { text: 'Hightech ruimtenetten', value: 'hightech ruimtenetten' },
      { text: 'Hightech silhouette', value: 'hightech silhouette' },
      { text: 'Tech & Play', value: 'tech&play' },
      { text: 'iGym Outdoor', value: 'igym outdoor' },
      { text: 'Overig', value: 'overig' }
    ],
    values: {
      'robuust robinia': 'Robuust robinia',
      'robuust staal': 'Robuust staal',
      'varioset mini': 'Varioset mini',
      'kidstramps': 'Kidstramps',
      'speelkunst': 'Speelkunst',
      'hightech adrenaline': 'Hightech adrenaline',
      'hightech fun4all': 'Hightech fun4all',
      'hightech klimtollen': 'Hightech klimtollen',
      'hightech ruimtenetten': 'Hightech ruimtenetten',
      'hightech silhouette': 'Hightech silhouette',
      'tech&play': 'Tech & Play',
      'igym outdoor': 'iGym Outdoor',
      'overig': 'Overig',
    }
  },
  fallingHeightGroup: {
    label: 'Valhoogte',
    items: [
      { text: 'n.v.t.', value: '0' },
      { text: 'tot 60cm', value: '60' },
      { text: 'tot 1.00 m', value: '100' },
      { text: 'tot 1.50 m', value: '150' },
      { text: 'boven 1.50 m', value: '150+' }
    ],
    values: {
      '0': 'n.v.t.',
      '60': 'tot 60 cm',
      '100': 'tot 1.00 m',
      '150': 'tot 1.50 m',
      '150+': 'boven 1.50 m'
    }
  },
  ageGroup: {
    multiple: true,
    label: 'Leeftijd',
    items: [
      { text: '0 - 3 jaar', value: '0-3' },
      { text: '3 - 6 jaar', value: '3-6' },
      { text: '6 - 9 jaar', value: '6-9' },
      { text: '9 - 12 jaar', value: '9-12' },
      { text: '12 - 15 jaar', value: '12-15' },
      { text: '15 jaar en ouder', value: '15+' }
    ],
    values: {
      '0-3': '0 - 3 jaar',
      '3-6': '3 - 6 jaar',
      '6-9': '6 - 9 jaar',
      '9-12': '9 - 12 jaar',
      '12-15': '12 - 15 jaar',
      '15+': '15 jaar en ouder'
    }
  },
  functionalities: {
    multiple: true,
    label: 'Speelfunctie',
    items: [
      { text: 'Apenkooien', value: 'apekooien' },
      { text: 'Balanceren', value: 'balanceren' },
      { text: 'Balspel', value: 'balspel' },
      { text: 'Constructiespel', value: 'constructiespel' },
      { text: 'Draaien', value: 'draaien' },
      { text: 'Duikelen', value: 'duikelen' },
      { text: 'Enteren', value: 'enteren' },
      { text: 'Glijden', value: 'glijden' },
      { text: 'In balans blijven', value: 'in balans blijven' },
      { text: 'Klimmen en klauteren', value: 'klimmen en klauteren' },
      { text: 'Kruipen', value: 'kruipen' },
      { text: 'Ontmoeten en zitten', value: 'ontmoeten/zitten' },
      { text: 'Oversteken', value: 'oversteken' },
      { text: 'Roetsjen', value: 'roetsjen' },
      { text: 'Rollen- en fantasiespel', value: 'rollen- en fantasiespel' },
      { text: 'Schommelen', value: 'schommelen' },
      { text: 'Slingeren', value: 'slingeren' },
      { text: 'Snelheid beleven', value: 'snelheid beleven' },
      { text: 'Spelen met/zonder beperking', value: 'spelen met/zonder beperking' },
      { text: 'Spelen met water', value: 'spelen met water' },
      { text: 'Springen en huppelen', value: 'springen en huppelen' },
      { text: 'Uitkijken', value: 'uitkijken' },
      { text: 'Wiebelen', value: 'wiebelen' },
      { text: 'Wiegen', value: 'wiegen' },
      { text: 'Wippen', value: 'wippen' },
      { text: 'Zintuigen en fijne motoriek', value: 'zintuigen en fijne motoriek' }
    ],
    values: {
      'apekooien': 'Apenkooien',
      'balanceren': 'Balanceren',
      'balspel': 'Balspel',
      'constructiespel': 'Constructiespel',
      'draaien': 'Draaien',
      'duikelen': 'Duikelen',
      'enteren': 'Enteren',
      'glijden': 'Glijden',
      'in balans blijven': 'In balans blijven',
      'klimmen en klauteren': 'Klimmen en klauteren',
      'kruipen': 'Kruipen',
      'ontmoeten/zitten': 'Ontmoeten en zitten',
      'oversteken': 'Oversteken',
      'roetsjen': 'Roetsjen',
      'rollen- en fantasiespel': 'Rollen- en fantasiespel',
      'schommelen': 'Schommelen',
      'slingeren': 'Slingeren',
      'snelheid beleven': 'Snelheid beleven',
      'spelen met/zonder beperking': 'Spelen met/zonder beperking',
      'spelen met water': 'Spelen met water',
      'springen en huppelen': 'Springen en huppelen',
      'uitkijken': 'Uitkijken',
      'wiebelen': 'Wiebelen',
      'wiegen': 'Wiegen',
      'wippen': 'Wippen',
      'zintuigen en fijne motoriek': 'Zintuigen en fijne motoriek'
    }
  }
}
