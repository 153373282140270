<template>
  <div
    v-editable="blok"
    class="page-container"
  >
    <component
      :key="blok._uid"
      v-for="blok in blok.body"
      :blok="blok"
      :is="blok.component | dashify"
    />
  </div>
</template>

<script>
export default {
  props: ['blok'],

  data() {
  	return {
      showInvoiceBlock: this.$attrs.full_slug !== 'privacy-verklaring' && this.$attrs.full_slug !== 'cookies'
    }
  },
}
</script>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";

  .page-container > section {
    &:not(.carousel):not(.banner) {
      max-width: $max-site-width;
      margin: 0 auto;
    }
  }
</style>
