import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

export default ({ app, route }) => {
  let locale = 'nl-NL';

  if (route.query.locale) {
    locale = route.query.locale;
  }
  else {
    const parts = route.fullPath.split('/');

    if (parts && parts.length >= 1) {
      switch(parts[1]) {
        case 'fr':
          locale = 'fr-FR';
          break;
        case 'de':
          locale = 'de-DE';
          break;
      }
    }
  }

  app.i18n = new VueI18n({
    locale,
    fallbackLocale: 'nl-NL',
    messages: {
      'nl-NL': require('~/locales/nl-NL.json'),
      'fr-FR': require('~/locales/fr-FR.json')
    }
  })
}
