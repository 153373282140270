<template>
  <div v-editable="blok">
    <component :key="blok._uid" v-for="blok in blok.body" :blok="blok" :is="blok.component | dashify" />

    <section>
      <v-container>
        <v-row>
          <v-col cols="12" sm="5" md="4" lg="3">
            <Menu/>
          </v-col>
          <v-col cols="12" sm="7" md="8" lg="9" class="overview">
            <v-row>
              <v-col
                v-if="blok.title || blok.text"
                cols="12"
              >
                <h1 v-if="blok.title">
                  {{ blok.title }}
                </h1>
                <v-btn
                  v-if="total > 0"
                  depressed
                  class="brand-orange mt-2 mb-3"
                  @click="scrollToItems"
                >
                  {{ currentItemsText }}
                </v-btn>
                <div
                  v-if="blok.text"
                  v-html="$md.render(blok.text)"
                  :class="{'body': true, 'mt-4': blok.title}"
                />
                <p
                  v-if="showExtraText"
                  class="read-more mt-3 mb-2"
                  @click="scrollToExtraText"
                >
                  Lees meer <v-icon>mdi-chevron-down</v-icon>
                </p>
              </v-col>

              <v-col
                ref="filters"
                cols="12"
                class="filters"
              >
                <v-row>
                  <v-col
                    v-for="(filter, key) in filters"
                    :key="`filter-${key}`"
                  >
                    <v-select
                      clearable
                      hide-details
                      filled
                      v-model="selectedFilters[key]"
                      @input="changeFilters()"
                      no-data-text="Geen filteropties"
                      :multiple="filter.multiple"
                      :label="filter.label"
                      :items="filter.items"
                    />
                  </v-col>
                  <v-col>
                    <v-btn
                      block
                      depressed
                      class="reset-filters"
                      color="rgb(240, 240, 240)"
                      :disabled="!filterIsActive"
                      @click="resetFilters"
                    >
                      {{ $t('empty filters') }}
                      <span v-if="activeFilterCount > 0"> ({{ activeFilterCount }})</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" class="py-0">
                <v-divider />
              </v-col>

              <template v-if="pages > 1">
                <v-divider />

                <v-pagination
                  v-model="page"
                  :length="pages"
                  :total-visible="10"
                  class="top-pagination"
                />
              </template>

              <v-col
                v-if="failedLoadingItems"
                cols="12"
              >
                <v-alert type="error">
                  Er is iets fout gegaan, de producten konden niet worden geladen. Probeer het a.u.b. later opnieuw.
                </v-alert>
              </v-col>

              <template
                v-else
                v-for="item in currentItems"
              >
                <v-col
                  cols="12" sm="6" md="4"
                  v-if="item.active"
                  :key="item.uuid"
                >
                  <product-item :item="item" :uid="item.uuid"/>
                </v-col>
              </template>
            </v-row>

            <template v-if="pages > 1">
              <v-divider class="mt-4" />

              <v-pagination
                v-model="page"
                :length="pages"
                :total-visible="10"
                v-on:input="scrollToTop"
              />
            </template>

            <div
              ref="extra-text"
              v-if="showExtraText"
              class="mt-12"
            >
              <div v-html="$md.render(blok.textUnderProducts)" />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
  import VueScrollTo from 'vue-scrollto';

	import Menu from '~/components/pages/ProductCategoryOverview/Menu';
  import ProductItem from '~/components/pages/ProductOverview/Product';

  import { productOverview } from '~/mixins/product-overview';

	export default {
		name: 'ProductOverviewPage',

		props: ['blok'],

    mixins: [ productOverview ],

    components: { Menu, ProductItem },

    computed: {
      showExtraText() {
        return this.blok.textUnderProducts &&
          !!this.blok.textUnderProducts.replace(/`/g, '').trim();
      }
    },

    methods: {
      scrollToTop() {
        VueScrollTo.scrollTo(this.$refs.filters, { offset: -80 });
      },
      scrollToItems() {
        VueScrollTo.scrollTo(this.$refs.filters, { offset: -200 });
      },
      scrollToExtraText() {
        if (this.$refs['extra-text']) {
          VueScrollTo.scrollTo(this.$refs['extra-text'], { offset: -80 });
        }
      }
    }
	}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";

  .v-btn.brand-orange {
    background-color: map-get($brand-colors, 'orange');
    color: #fff;
  }

  .body ::v-deep {
    ul {
      margin-bottom: 1rem;
    }

    p + ul {
      margin-top: -1rem;
    }
  }

  section {
    > .container {
      max-width: $max-site-width;
    }
  }

  .top-pagination {
    width: 100%;
  }

  #app .v-pagination.top-pagination {
    margin-top: $gutter-width / 2;
    margin-bottom: -$gutter-width;
  }

  .overview {
    ::v-deep .product {
      margin-top: $gutter-width;
    }
  }

  .v-btn.reset-filters {
    position: relative;
    height: 56px;

    text-transform: none;
    letter-spacing: normal;
    font-size: 1rem;

    color: rgba(0, 0, 0, .54);

    transition-property: box-shadow, transform, opacity, color;

    &:after {
      content: '';

      position: absolute;
      bottom: -1px;

      width: 100%;
      height: 1px;
      background: rgba(0, 0, 0, .42);
    }

    &:hover {
      color: rgba(0, 0, 0, .87);
    }
  }

  .filters {
    > .row > .col {
      min-width: 120px;
    }
  }

  .read-more {
    cursor: pointer;

    &, .v-icon {
      color: inherit;
      transition: color .2s;
    }

    &:hover {
      &, .v-icon {
        color: map-get($brand-colors, 'orange');
      }
    }
  }
</style>
