<template>
  <div v-editable="blok" itemscope itemtype="http://schema.org/Article">
    <meta itemprop="name" :content="this.$attrs.page_name">
    <meta itemprop="author" content="Goede Speelprojecten">
    <v-container>
      <v-row align="center" class="breadcrumbs">
        <v-col class="shrink">
          <nuxt-link :to="overviewUrl" class="back">
            <v-icon>mdi-chevron-left</v-icon>
            <span>overzicht</span>
          </nuxt-link>
        </v-col>
        <v-col>
          <v-breadcrumbs :items="breadcrumbs"/>
        </v-col>
      </v-row>
    </v-container>
    <component :key="blok._uid" v-for="blok in blok.body" :blok="blok" :is="blok.component | dashify" itemprop="articleBody"></component>
  </div>
</template>

<script>
  export default {
  	name: 'NewsPage',
    props: ['blok'],
	  data() {
		  return {
			  breadcrumbs: this.$store.state.breadcrumbs ? this.$store.state.breadcrumbs : [],
		  }
	  },
    computed: {
	    overviewUrl() {
		    return `/${(this.$attrs.full_slug).substring(0, ((this.$attrs.full_slug).lastIndexOf('/') === -1 ?
			    (this.$attrs.full_slug).length :
			    (this.$attrs.full_slug).lastIndexOf('/')))}`
	    },
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";

  .container {
    max-width: $max-site-width;
  }

  .v-breadcrumbs {
    padding: 0;
  }
</style>
