import { cloneDeep, difference, each, filter, size } from 'lodash';

import ProductItem from '@/components/pages/ProductOverview/Product';

import filters from '@/assets/js/filters';

export const productOverview = {
  components: { ProductItem },

  data() {
    return {
      items: [],
      currentItems: null,
      filters: filters,
      usableFilters: {},
      selectedFilters: {},
      failedLoadingItems: false,
      itemsLoaded: false,
      perPage: 30,
      total: 0,
      filteredTotal: 0,
      pages: 0,
      page: parseInt(this.$route.query.page) || 1
    }
  },

  computed: {
    activeFilterCount() {
      return Object.values(this.selectedFilters).filter(f => !!f).length;
    },

    filterIsActive() {
      return this.activeFilterCount > 0;
    },

    favorites() {
      return this.$store.state.favorites
    },

    currentItemsText() {
      let text = `${this.filteredTotal} toestel${this.filteredTotal > 1 ? 'len' : ''}`;

      if (this.filterIsActive) {
        text += ` van totaal ${this.total}`;
      }

      return text;
    }
  },

  watch: {
    async page(page) {
      if (!this.query && (this.page > 1 || this.$route.query.page)) {
        await this.$router.push({query: {page: page}})
      }

      this.itemsLoaded = true;
      await this.setCurrentItems();
    },
    '$route'(to, from) {
      // noinspection EqualityComparisonWithCoercionJS
      if (to.query.page != from.query.page) {
        this.page = parseInt(to.query.page);
      }
    },
    async filterIsActive(active) {
      if (active && !this.itemsLoaded) {
        this.itemsLoaded = true;
        await this.setCurrentItems();
      }
    }
  },

  async mounted() {
    if (!this.itemsLoaded) {
      this.itemsLoaded = true;
      await this.setCurrentItems();
    }
  },

  methods: {
    async setCurrentItems () {
      if (!this.currentItems || !this.items) {
        await this.loadItems()
      }

      const items = filter(cloneDeep(this.items), item => item.active);

      this.filteredTotal = Object.keys(items).length
      this.pages = Math.ceil(this.filteredTotal / this.perPage)

      if (this.page > this.pages) {
        this.$router.push({
          query: {
            page: '1', ...this.query ? { query: this.query} : {}
          }
        })
      }

      this.currentItems = items.slice(
        (this.page === 1 ? 0 : (this.page - 1) * this.perPage), this.page * this.perPage
      )
    },

    async loadItems() {
      let stories = [];

      if (this.$route.name === 'zoeken' && !this.query) {
        return stories;
      }

      try {
        let done = false;
        let total = 0;
        let page = 1;

        do {
          const params = {
            'filter_query[component][in]': 'page_product',
            'story_only': true,
            'is_published': true,
            'per_page': 100,
            'page': page++,
            'is_start_page': 0,
            'sort_by': 'position:DESC',
          };

          if (this.$attrs.full_slug) {
            params.starts_with = this.$attrs.full_slug;
          }

          if (this.query) {
            params.search_term = this.query;
          }

          const {data, headers} = await this.$storyapi.get('cdn/stories', params);

          total += data.stories.length;

          data.stories.forEach((story) => {
            let image = null;
            if ((story.content.images).length) {
              image = story.content.images[0].image
            }
            else if ((story.content.renders).length) {
              image = story.content.renders[0].image
            }
            else if ((story.content.blueprints).length) {
              image = story.content.blueprints[0].image
            }

            const filterOptions = {};

            Object.keys(this.filters || {}).forEach(key => {
              if (story.content[key]) {
                filterOptions[key] = story.content[key];
                if (!this.usableFilters[key]) {
                  this.usableFilters[key] = new Set()
                }
                if (typeof story.content[key] === 'object') {
                  (story.content[key]).forEach(item => this.usableFilters[key].add(item))
                } else if (typeof story.content[key] !== 'undefined') {
                  this.usableFilters[key].add(story.content[key])
                }
              }
            });

            stories.push({
              uuid: story.uuid,
              active: true,
              slug: story.full_slug,
              title: story.content.name,
              productLine: story.content.productLine,
              articleNumber: story.content.articleNumber,
              favorite: this.favorites.includes(story.uuid),
              image,
              filterOptions
            });
          });

          done = !headers.total || total >= headers.total;

          this.total = total;
        } while (!done);
      } catch {
        this.failedLoadingItems = true
      }

      this.items = stories;

      const filters = this.$store.getters.filters(this.blok._uid);
      if (filters.length) {
        this.selectedFilters = JSON.parse(filters);
        this.setFilters();
      }

      this.setUsableFilters();
    },

    setUsableFilters() {
      let usableFilters = [];

      if (filter(this.selectedFilters, size).length === 0) {
        usableFilters = cloneDeep(this.usableFilters)
      } else {
        each(this.items, story => {
          if (story.active) {
            Object.keys(this.filters || {}).forEach(key => {
              if (!usableFilters[key]) {usableFilters[key] = new Set()}
              if (typeof story.filterOptions[key] === 'object') {
                (story.filterOptions[key]).forEach(item => usableFilters[key].add(item))
              } else if (typeof story.filterOptions[key] !== 'undefined') {
                usableFilters[key].add(story.filterOptions[key])
              }
            })
          }
        });
      }

      // Remove filter options that won't have effect
      this.filters = each(cloneDeep(filters), ((_filter, key) => {
        _filter.items = filter(_filter.items, (
          item => usableFilters[key] ? usableFilters[key] === item.value || usableFilters[key].has(item.value) : false)
        )
      }));
    },

    setFilters() {
      each(this.items, item => {
        let active = true;

        Object.keys(this.filters || {}).forEach(key => {
          if (this.selectedFilters[key] && this.selectedFilters[key].length) {
            if (key === 'fallingHeightGroup') {
              const height = item.filterOptions[key] === '150+' ? 151 : item.filterOptions[key];

              if (
                !isNaN(height) && (
                  (this.selectedFilters[key] === '150+' && height < 151) ||
                  (this.selectedFilters[key] !== '150+' && parseInt(height) > this.selectedFilters[key])
                )
              ) {
                active = false;
              }
            }
            else if (key === 'productLine') {
              if (
                !(this.selectedFilters[key]).includes(item.filterOptions[key])
              ) {
                active = false
              }
            }
            else if (difference(this.selectedFilters[key], item.filterOptions[key]).length !== 0) {
              active = false
            }
          }
        });

        item.active = active;
      });
    },

    async changeFilters() {
      this.setFilters();
      this.setUsableFilters();
      this.storeFilters();
      await this.setCurrentItems();
    },

    storeFilters() {
      this.$store.commit('setFilters', {uid: this.blok._uid, filters: JSON.stringify(this.selectedFilters)});
    },

    async resetFilters() {
      this.selectedFilters = {};
      await this.changeFilters();
    }
  }
};
