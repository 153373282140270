<template>
  <div v-editable="blok">
    <component :key="blok._uid" v-for="blok in blok.body" :blok="blok" :is="blok.component | dashify"></component>
    <section id="news-overview">
      <v-container>
        <v-row>
          <v-col
            v-for="(item, i) in items" :key="i"
            cols="12" sm="6" md="4" lg="3"
          >
            <news-item :item="item"/>
          </v-col>
        </v-row>

        <v-divider v-if="pages > 1" class="mt-2"/>

        <v-pagination
          v-model="page"
          v-if="pages > 1"
          :length="pages"
          :total-visible="10"
        ></v-pagination>
      </v-container>
    </section>
  </div>
</template>

<script>
  import NewsItem from '@/components/NewsItem'
  const perPage = 30;

	export default {
		name: 'NewsOverviewPage',
		props: ['blok'],
    components: {NewsItem},
		data() {
			return {
				items: [],
        itemsLoaded: false,
				pages: 0,
				page: parseInt(this.$route.query.page) || 1,
			}
		},
		watch: {
			page(page) {
				if (this.page > 1 || this.$route.query.page) {
          this.$router.push({query: {page: page}})
        }
				this.itemsLoaded = true;
				this.loadItems()
			},
			'$route'(to, from) {
				// noinspection EqualityComparisonWithCoercionJS
				if (to.query.page != from.query.page) {
					this.page = parseInt(to.query.page)
				}
			}
		},
		methods: {
			async loadItems() {
        const params = {
	        'filter_query[seo_indexable][in]': 'yes',
	        'filter_query[component][in]': 'page_news',
	        'per_page': perPage,
	        'page': this.page,
	        'is_published': true,
	        'is_startpage': 0,
          'sort_by': 'position:ASC'
        }

        if (!this.blok.showAllNews) {
        	params['starts_with'] = this.$attrs.full_slug;
        }

				const storiesResult = await this.$storyapi.get('cdn/stories', params);

				if (!storiesResult.data.stories) {return;}

				this.pages = Math.ceil(storiesResult.total / perPage)

				let stories = {};
				storiesResult.data.stories.forEach((story) => {
          const date = story.content.date || null
					stories[story.uuid] = {
						slug: story.full_slug,
            date,
						title: story.name,
						text: story.content.overviewText,
						image: story.content.overviewImage,
            showDate: date && story.content.showDate === '1'
					};
				});

				this.items = stories;
			},
		},
		mounted() {
			if (!this.itemsLoaded) {
				this.itemsLoaded = true;
        this.loadItems()
      }
		},
	}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";

  #news-overview {
    > .container {
      max-width: $max-site-width;
    }
  }
</style>
