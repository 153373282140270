<template>
  <section
    v-editable="blok"
    :class="['text', 'grid', {'text-center': blok.textCenter, 'colored-background': blok.coloredBackground}]"
  >
    <v-container v-if="blok.title || blok.text">
      <v-row justify="center">
        <v-col
          cols="12"
          :md="blok.textCenter ? 10 : 12"
          :lg="blok.textCenter ? 8 : 12"
          :xl="blok.textCenter ? 7 : 12"
          class="body"
        >
          <h1
            v-if="blok.title"
            v-html="formatTitle(blok.title)"
            :class="[
              ...blok.underlineTitle && blok.underlineTitle !== 'none' ? [`underline-${blok.underlineTitle}`] : [],
              ...blok.titleColor ? [`text-${blok.titleColor}`] : []
            ]"
          ></h1>
          <div v-if="blok.text" v-html="$md.render(blok.text)"></div>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="blok.items && blok.items.length">
      <v-row :justify="blok.itemsCenter ? 'center' : 'start'">
        <v-col
          v-for="item in blok.items"
          :key="item._uid"
          cols="12"
          sm="6"
          md="4"
        >
          <grid-item
            v-editable="item"
            :item="item"
          />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
  import GridItem from '@/components/GridItem'

  export default {
    name: 'GridBlock',
    props: {
      blok: {
        type: Object,
        required: true
      }
    },
    components: {GridItem},
    methods: {
      formatTitle(string) {
        const pieces = string.split('\n')
        return pieces.length === 1 ? string : `<span>${pieces.join('</span><br><span>')}</span>`
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";

  section {
    &.colored-background {
      background: $colored-background;
    }

    &.text-center {
      h1[class*="underline"]:after {
        margin: 0 auto;
      }
    }

    .body {
      > :not(h1) {
        margin-top: $gutter-width;
      }

      h1 {
        display: inline-block;
        color: $text-color;

        @each $name, $color in $brand-colors {
          &.text-#{$name} {
            color: $color;
          }
        }

        &[class*="underline"] {
          &:after {
            content: '';

            display: block;
            max-width: 110px;

            padding-top: .5rem;
            border-bottom: 3px solid $text-color;
          }
        }

        &.underline-grey {
          &:after {
            border-color: map-get($grey, 'lighten-2');
          }
        }

        &.underline-blue {
          &:after {
            border-color: map-get($brand-colors, 'boring-blue');
          }
        }

        &.underline-green {
          &:after {
            border-color: map-get($brand-colors, 'green');
          }
        }

        &.underline-orange {
          &:after {
            border-color: map-get($brand-colors, 'orange');
          }
        }

        ::v-deep > span {
          color: $heading-text-color;
        }
      }
    }
  }
</style>
