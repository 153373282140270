<template>
  <div>
    <div
      v-if="formSent"
    >
      <v-alert
        type="info"
        color="#005d77"
      >
        {{ thanks }}
      </v-alert>
    </div>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="submit"
      v-else
    >
      <v-snackbar
        v-model="snackbar"
      >
        {{ snackbarMessage }}
        <v-btn
          color="#f19727"
          text
          @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-snackbar>

      <p>Organisatie:</p>
      <v-text-field
        v-model="form.organization"
        name="organization"
        outlined
        solo flat
        required
        :rules="rules.required"
        label="Naam van uw organisatie"
      ></v-text-field>

      <p>Projectnaam:</p>
      <v-text-field
        v-model="form.project"
        name="project"
        outlined
        solo flat
        label="De naam van uw project"
      ></v-text-field>

      <p>Naam:</p>
      <v-text-field
        v-model="form.name"
        name="name"
        outlined
        solo flat
        required
        :rules="rules.required"
        label="Uw naam"
      ></v-text-field>

      <p>Telefoon:</p>
      <v-text-field
        v-model="form.telephone"
        name="telephone"
        outlined
        solo flat
        required
        :rules="rules.required"
        label="0612345678"
      ></v-text-field>

      <p>E-mail:</p>
      <v-text-field
        v-model="form.email"
        name="email"
        outlined
        solo flat
        required
        :rules="rules.email"
        label="naam@email.nl"
      ></v-text-field>

      <p>Toelichting:</p>
      <v-textarea
        v-model="form.remark"
        name="remark"
        outlined
        solo flat
        rows="8"
        label="Voeg hier uw opmerking of vraag toe"
      ></v-textarea>

      <p class="recaptcha">Deze site wordt beschermd door reCAPTCHA en de <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> van Google en de <a href="https://policies.google.com/terms" target="_blank">Algemene voorwaarden</a> zijn van toepassing.</p>

      <v-checkbox
        v-model="form.agreed"
        :input-value="true"
        :rules="[v => !!v || 'U dient akkoord te gaan met de voorwaarden']"
      >
        <div slot="label">
          Hierbij verleen ik aan Goede Speelprojecten toestemming tot verwerking van mijn persoonsgegevens.
          Voor verdere informatie hieromtrent zie <span @click.capture.stop.prevent="goToPrivacy" id="go-to-privacy">Privacy Verklaring</span>.
        </div>
      </v-checkbox>

      <p>&nbsp;</p>

      <v-row justify="center">
        <v-col cols="8" sm="6" md="5" lg="4">
          <v-btn
            type="submit"
            block
            rounded
            x-large
            outlined
            color="#f19727"
            class="submit"
            :loading="loading"
            :disabled="!valid"
          >
            Versturen
            <v-icon right>icon-paper-plane</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
  export default {
  	name: 'InvoiceForm',
    props: {
  		thanks: {
  			type: String,
        default: 'Bedankt voor het invullen van het formulier. Wij nemen z.s.m. contact met u op!'
      }
    },
    data() {
      return {
        valid: true,
        loading: false,
        form: {
          organization: '',
          project: '',
          name: '',
          telephone: '',
          email: '',
          remark: '',
          agreed: false
        },
        recaptcha: null,
        formSent: null,
        rules: {
          required: [
            v => !!v || 'Dit veld dient ingevuld te worden'
          ],
          email: [
            v => !!v || 'Vul een e-mailadres in',
            v => /.+@.+/.test(v) || 'Vul een geldig e-mailadres in'
          ]
        },
        snackbar: false,
        snackbarMessage: ''
      }
    },
    methods: {
  		async submit() {
			  if (!this.$refs.form.validate()) {
			  	this.snackbar = true;
			  	this.snackbarMessage = 'U heeft niet alle verplichte velden ingevuld'
				  return;
			  }

			  this.loading = true

			  try {
				  const token = await this.recaptcha.execute('invoice')
				  let products = []
          const uuids = (JSON.parse(JSON.stringify(this.$store.state.favorites))).join(',')

				  const stories = await this.$storyapi.get('cdn/stories', {
					  'by_uuids_ordered': uuids,
					  'story_only': true,
					  'is_published': true,
					  'per_page': 100,
					  'sort_by': 'position:DESC',
				  });

				  stories.data.stories.forEach((story) => {
					  products.push({
						  name: story.content.name,
						  articleNumber: story.content.articleNumber,
						  image: story.content.images && (story.content.images).length ?
							  this.$options.filters.resize(story.content.images[0].image, '500x0') : null
					  });
				  });

				  await this.$axios.$post('/api/invoice', {
				  	data: {
				  		'Organisatie': this.form.organization,
              'Projectnaam': this.form.project || '-',
              'Naam': this.form.name,
              'Telefoon': this.form.telephone,
              'E-mailadres': this.form.email,
              'Toelichting': this.form.remark
            },
            ids: uuids,
            products,
            mail_to: this.form.email,
            subject: 'Offerte aanvraag - Goede Speelprojecten', token
				  })

				  this.formSent = true
          this.loading = false
			  }
			  catch (e) {
				  console.error(e.response.data)
          this.loading = false
				  this.snackbar = true
				  this.snackbarMessage = 'De recaptcha verificatie is mislukt. Ververs a.u.b. de pagina en probeer het opnieuw.'
			  }
      },
	    goToPrivacy() {
        window.open('/privacy-verklaring', '_blank')
      }
    },
	  async mounted() {
		  this.recaptcha = await this.$recaptcha();
	  },
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";

  .v-form {
    text-align: left;

    ::v-deep .v-label {
      font-style: italic;
      width: 100%;
      color: map-get($grey, 'lighten-1');
    }

    .v-input--checkbox {
      ::v-deep .v-icon {
        pointer-events: none;
        position: relative;

        &:before {
          z-index: 10;
        }

        &:after {
          content: '';
          position: absolute;
          top: 4px;
          left: 4px;
          right: 4px;
          bottom: 4px;
          background: #fff;
          z-index: 1;
        }
      }

      ::v-deep label {
        color: $text-color;
        font-style: normal;
      }

      &.v-input--is-label-active {
        ::v-deep .v-icon {
          color: map-get($brand-colors, 'blue');
        }
      }
    }

    .v-btn.submit {
      background: #fff;
      font-weight: $font-weight-bold;
      text-transform: none;
      letter-spacing: 0;

      .v-icon {
        font-size: 1.5rem;
      }
    }
  }

  p {
    margin: 0 0 $gutter-width / 2 0;
    font-size: 110%;
  }

  #go-to-privacy {
    color: $link-color;
    text-decoration: underline;
    transition: color .2s;

    &:hover {
      color: $link-hover-color;
    }
  }

  .recaptcha {
    font-size: 100%;
  }
</style>
