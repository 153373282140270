<template>
  <div :class="columnClass">
    <div v-if="video">
      <VideoEmbed
        :source="video"
        :image="image || null"
      />
    </div>
    <div v-else-if="image" :class="imageClass">
      <div
        ref="image-container"
        class="inner"
        :style="{paddingTop: imageRatio}"
      >
        <app-link
          :to="$storyblok.url(link)"
          class="v-responsive v-image"
        >
          <v-img
            itemprop="image"
            :src="image | resize('1000x0')"
            :lazy-src="image | resize('100x0')"
            :srcset="srcset(image)"
            sizes="auto"
            :alt="imageAlt"
          >
            <template slot="placeholder">
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="#00A8E7"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </app-link>
      </div>
    </div>

    <div v-else-if="title || text || link" class="content">
      <h2 v-if="title" v-html="formatTitle(title)"></h2>
      <div v-if="text" v-html="$md.render(text)"></div>
      <app-link
        v-if="$storyblok.isLink(link)"
        :to="$storyblok.url(link)"
      >
        <slot v-if="linkText">{{ linkText }}</slot>
      </app-link>
    </div>
  </div>
</template>

<script>
	import AppLink from '@/components/util/AppLink'
	import VideoEmbed from '@/components/util/VideoEmbed'

  export default {
  	name: 'ColumnsItem',
    props: ['blok', 'side'],
    components: { AppLink, VideoEmbed },
    computed: {
      columnClass() {
        return `column column-${this.side}`;
      },
      video() {
        return this.blok[this.getKey('video')];
      },
      imageClass() {
        return `image ${this.imageStyle}`;
      },
      image() {
        return this.blok[this.getKey('image')];
      },
      imageAlt() {
        return this.blok[this.getKey('imageAlt')];
      },
      imageStyle() {
        return this.blok[this.getKey('imageStyle')];
      },
      imageRatio() {
        const src = this.image;
        const values = !src ? [] : src.split('/');

        if (values.length) {
          const size = values[5].split('x');
          return (size[1] / size[0]) * 100 + '%';
        }

        return null;
      },
      title() {
        return this.blok[this.getKey('title')];
      },
      text() {
        return this.blok[this.getKey('text')];
      },
      link() {
        return this.blok[this.getKey('link')];
      },
      linkText() {
        return this.blok[this.getKey('linkText')];
      }
    },
    methods: {
      getKey(k) {
        return k + (this.side).charAt(0).toUpperCase() + (this.side).slice(1);
      },
	    formatTitle(string) {
		    const pieces = string.split('\n')
		    return pieces.length === 1 ? string : `<span>${pieces.join('</span><br><span>')}</span>`
	    },
	    srcset(image, ratio) {
		    const that = this;
		    let src = '';

		    [400, 600, 900, 1200, 1400].forEach((width) => {
			    src += `${that.$options.filters.resize(image, `${width}x${ratio ? Math.round(width / ratio) : 0}`)} ${width}w, `;
		    });

		    src = src.slice(0, -2);

		    return src;
	    },
    },
    mounted() {
  	  if (this.$refs['image-container']) {
  	    if (this.$refs['image-container'].clientHeight > this.$refs['image-container'].parentElement.clientHeight) {
          this.$refs['image-container'].parentElement.style.maxWidth = `${this.$refs['image-container'].parentElement.clientHeight}px`;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";

  h2 {
    display: inline-block;
    color: $heading-text-color;

    ::v-deep > span {
      color: $heading-text-color;
    }

    + div {
      margin-top: $gutter-width;
    }
  }
</style>
