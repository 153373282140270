export const state = () => ({
  version: 'draft',
	cacheVersion: Date.now(),
	draftMode: false,
  favorites: [],
  filters: {}
});

export const mutations = {
  setVersion(state, value) {
    state.version = value;
  },
	setCacheVersion (state, version) {
		state.cacheVersion = version
	},
	setDraftMode(state, draftMode) {
		state.draftMode = draftMode;
	},
	setBreadcrumbs(state, breadcrumbs) {
		state.breadcrumbs = breadcrumbs;
	},
	setLinks(state, links) {
		state.links = links;
	},
	setSettings(state, settings) {
		state.settings = settings;
	},
  setFavorites(state, favorites) {
	  state.favorites = favorites;
  },
  setProductCategoryLinks(state, links) {
	  state.productCategoryLinks = links;
  },
  setFilters(state, {uid, filters}) {
	  state.filters[uid] = filters;
  }
};

export const getters = {
  favorites(state) {
    return state.favorites
  },
  favoriteCount(state) {
    return (state.favorites).length
  },
  filters: (state) => (uid) => {
    if (uid) {
      return (state.filters).hasOwnProperty(uid) ? state.filters[uid] : []
    }

    return state.filters
  }
}
