/**
 * Get slug
 *
 * @param {string} value
 *
 * @returns {string}
 */
const getSlug = (value) => {
  if (!value) {
    return '';
  }

  return value
    .replace(/^\//, '')
    .replace('home', '')
    .replace(/\/$/, '');
};

/**
 * Is link
 *
 * @param {{}} item
 *
 * @returns {boolean}
 */
const isLink = (item) => {
  if (item?.linktype === 'email') {
    return !!item.email;
  }

  return !!item?.cached_url;
};

/**
 * Story url
 *
 * @param {string} uuid
 * @param {Object<string, Object>} links
 *
 * @returns {?string}
 */
const storyUrl = (uuid, links) => {
  if (!uuid || !links[uuid]) {
    return null;
  }

  const slug = getSlug(links[uuid].slug);

  return `/${slug}`;
};

/**
 * Url
 *
 * @param {{}} item
 *
 * @returns {?string}
 */
const url = (item) => {
  if (item?.linktype === 'story') {
    const slug = getSlug(item.cached_url);
    const anchor = item.anchor ? `#${item.anchor}` : '';

    return `/${slug}${anchor}`;
  } else if (item?.linktype === 'url' || item?.linktype === 'asset') {
    return item.cached_url;
  } else if (item?.linktype === 'email') {
    return item.email ? `mailto:${item.email}` : null;
  } else {
    return null;
  }
};

/**
 * Get cache version
 *
 * @param {import('storyblok-js-client').default} $storyapi
 *
 * @returns {Promise<number>}
 */
const getCacheVersion = async ({ $storyapi }) => {
  const { space } = await $storyapi
    .get('cdn/spaces/me')
    .then((response) => response.data);

  return space?.version ?? Date.now();
};

/**
 * Get settings
 *
 * @param {import('vuex').default} store
 * @param {import('storyblok-js-client').default} $storyapi
 *
 * @returns {Promise<Object>}
 */
const getSettings = async ({
  store,
  $storyapi
}) => {
  const { story } = await $storyapi
    .get('cdn/stories/settings', {
      cv: store.state.cacheVersion,
      version: store.state.version
    })
    .then((response) => response.data);

  return story?.content ?? {};
};

/**
 * Get links
 *
 * @param {import('vuex').default} store
 * @param {import('storyblok-js-client').default} $storyapi
 *
 * @returns {Promise<Object<string, Object>>}
 */
const getLinks = async ({
  store,
  $storyapi
}) => {
  const { links } = await $storyapi
    .get('cdn/links', {
      cv: store.state.cacheVersion,
      version: store.state.version
    })
    .then((response) => response.data);

  return links ?? {};
};

export default async (context, inject) => {
  const {
    store,
    route,
    isDev
  } = context;

  const version = route.query._storyblok || isDev ? 'draft' : 'published';
  store.commit('setVersion', version);

  const cacheVersion = await getCacheVersion(context);
  store.commit('setCacheVersion', cacheVersion);

  const settings = await getSettings(context);
  store.commit('setSettings', settings);

  const links = await getLinks(context);
  store.commit('setLinks', links);

  const storyblok = {
    url,
    isLink,
    storyUrl: (uuid) => storyUrl(uuid, links)
  };

  context.$storyblok = storyblok;
  inject('storyblok', storyblok);
};
