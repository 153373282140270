<template>
	<div>
		<h1 v-if="blok.title">{{ blok.title }}</h1>
		<div v-if="blok.text" v-html="$md.render(blok.text)"></div>
	</div>
</template>

<script>
	export default {
		props: ['blok'],
	}
</script>

<style lang="scss" scoped>
	@import "@/assets/scss/_variables.scss";

	h1 + div
	{
		margin-top: $gutter-width;
	}
</style>
