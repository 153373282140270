export default function (req, res, next) {
	const host = req.headers.host
	const url = req.url
	const env = process.env.NODE_ENV
	const canonicalDomain = process.env.CANONICAL_DOMAIN // e.g. www.destination_domain.com

	if (env === 'production') {
    if (host.includes('sportprojecten.com')) {
      res.writeHead(302, { Location: 'https://' + canonicalDomain + '/sporttoestellen' })
      return res.end()
    }

    if (
      host.includes('igym-outdoor.com') ||
      host.includes('igymoutdoor.com')
    ) {
      res.writeHead(302, { Location: 'https://' + canonicalDomain + '/sporttoestellen/igym-outdoor-fitness' })
      return res.end()
    }


    if (canonicalDomain && host !== canonicalDomain) {
      res.writeHead(301, { Location: 'https://' + canonicalDomain + url })
      return res.end()
    }
  }

	return next()
}
