export default ({ app, route }) => {
  if (process.env.NODE_ENV !== 'production') {return}

  if (route.fullPath.indexOf('product-datasheet') === -1) {return}

  /*
  ** Only run when consent has been given
  */
  let accepted = localStorage.getItem('cookie:accepted')
  if (!accepted || typeof accepted === 'undefined') app.$cookies.get('cookie:accepted')
  if (!accepted || typeof accepted === 'undefined') return

  (function(h,o,t,j,a,r){
    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    h._hjSettings={hjid:707312,hjsv:6};
    a=o.getElementsByTagName('head')[0];
    r=o.createElement('script');r.async=1;
    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
}
