<template>
  <section
    v-editable="blok"
    class="video"
  >
    <video-embed
      v-if="blok.source"
      :source="blok.source"
      :image="blok.image"
    />
  </section>
</template>

<script>
import VideoEmbed from '@/components/util/VideoEmbed';

export default {
  name: 'VideoBlock',

  components: { VideoEmbed },

  props: {
    blok: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  section.video + section.video {
    margin-top: 1rem;
  }
</style>
