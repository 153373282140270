const base = '/productlijnen'

module.exports = {
  'robuust robinia': `${base}/robuust-robinia`,
  'robuust staal': `${base}/robuust-staal`,
  'robuust design': `${base}/robuust-robinia`,
  'varioset mini': `${base}/robuust-robinia`,
  'kidstramps': `${base}/hightech`,
  'speelkunst': `${base}/speelkunst`,
  'hightech adrenaline': `${base}/hightech`,
  'hightech amico': `${base}/hightech`,
  'hightech fun4all': `${base}/hightech`,
  'hightech klimtollen': `${base}/hightech`,
  'hightech ruimtenetten': `${base}/hightech`,
  'hightech silhouette': `${base}/hightech`,
}
