import Vue from 'vue'

Vue.filter('resize', (image, size) => {
	if (typeof image !== 'undefined') {
		return '//img2.storyblok.com/' + size + '/smart' + (image.replace('//a.storyblok.com', '').replace('https:', ''));
	}
	return null;
})

Vue.filter('transform', (image, options) => {
  if (image.includes('img2.storyblok.com')) {
    return image;
  }
  if (typeof image !== 'undefined') {
    return '//img2.storyblok.com/' + options + (image.replace('//a.storyblok.com', '').replace('https:', ''));
  }
  return null;
})

Vue.filter('dashify', function(value) {
	if (typeof value === 'undefined') {
		return 'undefined'
	}

	let dashified = value.toString()
		.replace(/([A-Z])/g, ' $1')
		.trim()
		.toLowerCase()
		.replace(/[ _]/g, '-');

	return 'blok-' + dashified;
})

Vue.filter('data-size', (url, resized) => {
	if (url) {
		let sizes = url.split('/')[resized ? 6 : 5].split('x');
		sizes = sizes.filter(item => item);

		if (sizes && sizes.length === 2) {
			if (resized) {
				const width = url.split('/')[3].split('x')[0];

				sizes = [width, sizes[1] * (width / sizes[0])];
			}

			return `{"width": ${parseInt(sizes[0])}, "height": ${parseInt(sizes[1])}}`;
		}
	}

	return `{"width": 200, "height": 200}`;
});
